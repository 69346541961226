<template>
  <el-row>
    <!--<el-col :class="$langConfig.cssClass" style="width: 160px;">
      <dg-text-field
          :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
          :field_span="16"
          :label_span="7"
          :translateLabel='false'
          field_mark="required"
          label="TVQ"
          placeholder="xxx-xxx-xxx"
          maxLength="11"
          :liveUpdate="true"
          name="tax_tvq_number"
          @amUpdated="part1Updated"
          rules="required"></dg-text-field>
    </el-col>-->
    <el-col :class="$langConfig.cssClass" style="width: 160px;">
      <dg-text-field
          :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
          :field_span="16"
          :label_span="7"
          :translateLabel='false'
          field_mark="required"
          label="TVQ"
          placeholder="xxxxxxxxxx"
          maxLength="10"
          name="tax_tvq_number"
          rules="required"></dg-text-field>
    </el-col>
    <el-col :class="$langConfig.cssClass" style="width: 75px">
      <dg-text-field
          defaultValue="TQ0001"
          :control="control" :editable="control.editable" :disabled="control.ctrlsDisabled"
          :field_span="23"
          :label_span="1"
          :translateLabel='false'
          field_mark=""
          label=""
          name="tax_tvq_number_code"
          placeholder="TQ0001"
          rules=""></dg-text-field>
    </el-col>
  </el-row>
</template>

<script>
export default {
  methods: {
    mask (value) {
      let output = [];
      for(let i = 0; i < value.length; i++) {
        if(i !== 0 && i % 3 === 0) {
          output.push("-"); // add the separator
        }
        output.push(value[i]);
      }
      return output.join("")
    },
    unmask (val) {
      return val.replace(new RegExp(/[^\d]/, 'g'), ''); // Remove every non-digit character
    },
    part1Updated (props) {
      let value = props.field.field_value
      value = this.unmask(value)
      props.field.field_value =  this.mask(value);
    }
  },
  props: {
    control: {
      required :true
    },
    editMode: {
      required :true
    }
  }
}
</script>
